<template>
  <div class="banner mb-70">
    <el-carousel height="80vh">
      <el-carousel-item v-for="(item,index) in imgsList" :key="index">
        <img :src=item.url alt="">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
   export default({
    data(){
        return {
            imgsList:[
                {url:require('../assets/images/banner01.jpg')},
                {url:require('../assets/images/banner02.jpg')},
                {url:require('../assets/images/banner03.jpg')},
                {url:require('../assets/images/banner04.jpg')},
             
            ]
        }
    },
   })
</script>

<style scoped lang="less" >
.banner{
    img{
        object-fit: cover;
    }
}
</style>