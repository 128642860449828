var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"container flex"},[_vm._m(0),_c('div',{staticClass:"nav"},_vm._l((_vm.menus),function(item,index){return _c('div',{key:index,staticClass:"menu-item"},[_c('div',{staticClass:"item",class:[_vm.isToggle && _vm.currentIndex === index ? 'up font-bold' : 'down'],on:{"click":function($event){return _vm.toggle(index)}}},[_c('span',{class:{ 'item-border': _vm.isToggle && _vm.currentIndex === index }},[_vm._v(_vm._s(item))])]),_c('div',{class:['item-content', { 'item-content-height': _vm.isToggle && _vm.currentIndex === index }]},[[(index === 0)?_c('div',{staticClass:"toggle-content"},[_c('div',{staticClass:"product flex-center"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"row"},[_c('ul',[_vm._l((2),function(item){return _c('li',[_c('img',{attrs:{"src":require("../assets/images/pro5.jpg"),"alt":""}}),_c('p',[_vm._v("西门子")])])}),_vm._l((3),function(item){return _c('li',[_c('img',{attrs:{"src":require("../assets/images/pro.jpg"),"alt":""}}),_c('p',[_vm._v("三菱")])])}),_vm._l((2),function(item){return _c('li',[_c('img',{attrs:{"src":require("../assets/images/pro4.jpg"),"alt":""}}),_c('p',[_vm._v("S7-200PLC")])])}),_vm._l((3),function(item){return _c('li',[_c('img',{attrs:{"src":require("../assets/images/pro2.jpg"),"alt":""}}),_c('p',[_vm._v("欧姆龙PLC")])])})],2)])]),_vm._m(1,true)])]):(index === 1)?_c('div',[_vm._m(2,true)]):(index === 2)?_c('div',[_vm._m(3,true)]):(index === 3)?_c('div',[_vm._m(4,true),_vm._m(5,true)]):(index === 4)?_c('div',[_c('h3',[_vm._v("关于绿源天成的标题")]),_c('p',[_vm._v("关于绿源天成的内容")])]):_vm._e()]],2)])}),0),_c('div',{staticClass:"search"},[_c('el-input',{attrs:{"placeholder":"请输入内容"},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}},[_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"prefix"},slot:"prefix"})])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo flex-center"},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_vm._v(" 绿源天成 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("../assets/images/p60.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drop-container"},[_c('div',{staticClass:"row flex"},[_c('div',{staticStyle:{"width":"50%"}},[_c('h3',{staticClass:"row-header"},[_vm._v("产品")]),_c('div',[_c('h3',[_vm._v("联接")]),_c('ul',[_c('li',[_vm._v("运营商网络")]),_c('li',[_vm._v("企业网络")]),_c('li',[_vm._v("企业光网络")]),_c('li',[_vm._v("企业无线")])])])]),_c('div',{staticStyle:{"width":"15%"}},[_c('h3',{staticClass:"row-header"},[_vm._v("服务")])]),_c('div',{staticStyle:{"width":"15%"}},[_c('h3',{staticClass:"row-header"},[_vm._v("行业解决方案")])]),_c('div',{staticStyle:{"width":"15%"}},[_c('h3',{staticClass:"row-header"},[_vm._v("热点话题")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("服务支持项目1")]),_c('li',[_vm._v("服务支持项目2")]),_c('li',[_vm._v("服务支持项目3")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"合作伙伴图片1.jpg","alt":"合作伙伴图片1"}}),_c('p',[_vm._v("合作伙伴介绍1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"合作伙伴图片2.jpg","alt":"合作伙伴图片2"}}),_c('p',[_vm._v("合作伙伴介绍2")])])
}]

export { render, staticRenderFns }