<template>
    <div class="Recommend-information">
        <div class="title mb-70">
            <h2>推荐信息</h2>
        </div>
        <div class="product">
            <div class="top flex-between mb-40">
                <div class="image-container" style=" width: 65%;">
                    <img src="../assets/images/good02.jpg" alt="Image">
                    <div class="overlay"></div>
                    <div class="description">这是图片描述</div>
                </div>
                <div class="image-container" style="width: 30%;">
                    <img src="../assets/images/good01.jpg" alt="Image">
                    <div class="overlay"></div>
                    <div class="description">这是图片描述</div>
                </div>
            </div>
            <div class="middle flex-between mb-40">
                <div class="image-container" style="width: 30%;">
                    <img src="../assets/images/good05.jpg" alt="Image">
                    <div class="overlay"></div>
                    <div class="description">这是图片描述</div>
                </div>
                <div class="image-container" style="width: 65%;">
                    <img src="../assets/images/good04.jpg" alt="Image">
                    <div class="overlay"></div>
                    <div class="description">这是图片描述</div>
                </div>
            </div>
            <div class="bottom flex-between ">
                <div class="image-container" style="width: 30%;">
                    <img src="../assets/images/good03.jpg" alt="Image">
                    <div class="overlay"></div>
                    <div class="description">这是图片描述</div>
                </div>
                <div class="image-container" style="width: 30%;">
                    <img src="../assets/images/good03.jpg" alt="Image">
                    <div class="overlay"></div>
                    <div class="description">这是图片描述</div>
                </div>
                <div class="image-container" style="width: 30%;">
                    <img src="../assets/images/good03.jpg" alt="Image">
                    <div class="overlay"></div>
                    <div class="description">这是图片描述</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default ({
    name: '',
    data() {
        return {
        }
    },
})
</script>

<style scoped lang="less" >
.image-container {
    &:hover img {
        transform: scale(1.2);
    }

    &:hover .overlay {
        opacity: 1;
    }

    // width: 60%;
    height: 300px;
    position: relative;
    overflow: hidden;

    img {
        object-fit: cover;
        transition: transform 0.3s ease-in-out;
    }

    .overlay {
        width: 100%;
        height: 70%;
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 70%);
        position: absolute;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .description {
        position: absolute;
        bottom: 10px;
        /* 调整文字描述距离容器底部的距离 */
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        text-align: center;
        opacity: 0;
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
}
.image-container:hover .description {
  transform: translateX(-50%) translateY(-20px); /* 调整文字描述向上移动的距离 */
  opacity: 1;
}
.Recommend-information {
    width: 80%;
    margin: auto;
    // background-color: pink;
    // height: 1000px;
    text-align: center;
    padding-bottom: 130px;
    .title {

        h2 {
            color: #333;
            position: relative;
            padding-bottom: 8px;
            display: inline-block;
            max-width: 80%;

            &::after {
                content: '';
                width: 3%;
                height: 2px;
                background-color: #c7000b;
                position: absolute;
                left: 50%;
                bottom: 0;
                min-width: 35px;
                margin-left: 0;
                transform: translateX(-50%);
            }

        }
    }
}</style>