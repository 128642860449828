<template>
  <div class="home">
    <NavBar/>
    <HeaderView/>
    <Banner/>
    <RecommendInfo/>
    <News/>
    <FooterView/>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from './NavBar.vue';
import HeaderView from './HeaderView.vue';
import Banner from './Banner.vue';
import RecommendInfo from './RecommendInfo.vue';
import News from './News.vue'
import FooterView from './FooterView.vue'
export default {
  name: 'HomeView',
  components: {
    NavBar,
    HeaderView,
    Banner,
    RecommendInfo,
    News,
    FooterView
  }
}
</script>
<style lang="less" scoped>
</style>