<template>
    <div class="footer">
        <div class="container">
            <div class="top-line">首页</div>
            <footer class="flex">
                <div class="col">
                    <h3>关于绿源天成</h3>
                    <ul>
                        <li>公司简介</li>
                        <li>可持续发展</li>
                    </ul>
                </div>
                <div class="col">
                    <h3>新闻与活动</h3>
                    <ul>
                        <li>新闻</li>
                        <li>展会活动</li>
                    </ul>
                </div>
                <div class="col">
                    <h3>发现绿源天成</h3>
                    <ul>
                        <li>绿源天成技术专栏</li>
                        <li>跃升数字生产力</li>
                    </ul>
                </div>
                <div class="col">
                    <h3>我们的产品</h3>
                    <ul>
                        <li>个人及家庭产品</li>
                        <li>企业业务产品</li>
                    </ul>
                </div>
                <div class="col">
                    <h3>技术支持</h3>
                    <ul>
                        <li>消费者技术支持</li>
                        <li>华为云技术支持</li>
                    </ul>
                </div>
            </footer>
        </div>
        <div class="copy-text flex-center">
            <span>

                <span id="footerCopyRight">
                    <span>©2023 绿源天成技术有限公司&nbsp;</span>
                    <span><a href="http://beian.miit.gov.cn">京ICP备17035908号-1</a>&nbsp;&nbsp;</span></span>

                <div class="line"></div>
            </span>
            <ul class="list-unstyled flex">
                <li><a href="/cn/contact-us"> 联系我们</a></li>
                <li><a href="/cn/legal">法律声明</a></li>
                <li><a href="/cn/privacy-policy">隐私政策</a></li>
                <li> <a href="/cn/terminations">除名查询</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default ({
    name: '',
    data() {
        return {
        }
    },
})
</script>

<style scoped lang="less" >
.footer {
    background-color: #f2f2f2;
    // height: 1000px;

    .container {
        width: 80%;
        margin: 0 auto;

        .top-line {
            padding: 40px 0;
            border-bottom: 1px solid #e2e2e2;
        }

        footer {
            margin: 40px 0;

            .col {
                width: 20%;
            }

            h3 {
                margin-bottom: 20px;
                font-size: 16px;
            }

            ul {
                color: #595757;
                font-size: 14px;

                li {
                    padding: 10px 0;
                }
            }
        }
    }
    .copy-text{
        background-color: #e6e6e6;
        padding: 16px 0;
        font-size: 14px;
        color: #595757;
        a{
            color: inherit;
        }
        span{
            margin: 0 10px;
        }
        .line{
            width: 1px;
            height: 14px;
            background: #c2c3c3;
            display: inline-block;
            margin: 0 8px 0 8px;
        }
        ul{
            
            li{
                padding: 0 10px;
            }
        }
    }
}</style>