<template>
  <div class="header ">
    <div class="container flex">
      <!-- logo -->
      <div class="logo flex-center">
        <div class="img-box"><img src="../assets/logo.png" alt=""></div>
        绿源天成
      </div>
      <!-- 菜单 -->
      <div class="nav">
        <div class="menu-item" v-for="(item, index) in menus" :key="index">
          <div class="item" @click="toggle(index)"
            :class="[isToggle && currentIndex === index ? 'up font-bold' : 'down']">
            <span :class="{ 'item-border': isToggle && currentIndex === index }">{{ item }}</span>
          </div>
          <div :class="['item-content', { 'item-content-height': isToggle && currentIndex === index }]">
            <template>
              <div v-if="index === 0" class="toggle-content"> <!-- 对应菜单项 '智能产品' 的内容 -->
                <div class="product flex-center">
                  <div class="left">
                    <div class="row">
                      <ul>
                        <li v-for="item in 2">
                          <img src="../assets/images/pro5.jpg" alt="">
                          <p>西门子</p>
                        </li>
                        <li v-for="item in 3">
                          <img src="../assets/images/pro.jpg" alt="">
                          <p>三菱</p>
                        </li>
                        <li v-for="item in 2">
                          <img src="../assets/images/pro4.jpg" alt="">
                          <p>S7-200PLC</p>
                        </li>
                        <li v-for="item in 3">
                          <img src="../assets/images/pro2.jpg" alt="">
                          <p>欧姆龙PLC</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="right">
                    <img src="../assets/images/p60.jpg" alt="">
                  </div>
                </div>
              </div>
              <div v-else-if="index === 1"> <!-- 对应菜单项 '系统集成' 的内容 -->
                <div class="drop-container">
                  <div class="row flex">
                    <div style="width: 50%;">
                      <h3 class="row-header">产品</h3>
                      <div>
                        <h3>联接</h3>
                        <ul>
                          <li>运营商网络</li>
                          <li>企业网络</li>
                          <li>企业光网络</li>
                          <li>企业无线</li>
                        </ul>
                      </div>
                    </div>
                    <div style="width: 15%;">
                      <h3 class="row-header">服务</h3>
                    </div>
                    <div style="width: 15%;">
                      <h3 class="row-header">行业解决方案</h3>
                    </div>
                    <div style="width: 15%;">
                      <h3 class="row-header">热点话题</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="index === 2"> <!-- 对应菜单项 '服务支持' 的内容 -->
                <ul>
                  <li>服务支持项目1</li>
                  <li>服务支持项目2</li>
                  <li>服务支持项目3</li>
                </ul>
              </div>
              <div v-else-if="index === 3"> <!-- 对应菜单项 '合作伙伴' 的内容 -->
                <div>
                  <img src="合作伙伴图片1.jpg" alt="合作伙伴图片1">
                  <p>合作伙伴介绍1</p>
                </div>
                <div>
                  <img src="合作伙伴图片2.jpg" alt="合作伙伴图片2">
                  <p>合作伙伴介绍2</p>
                </div>
              </div>
              <div v-else-if="index === 4"> <!-- 对应菜单项 '关于绿源天成' 的内容 -->
                <h3>关于绿源天成的标题</h3>
                <p>关于绿源天成的内容</p>
              </div>
            </template>

          </div>
        </div>
      </div>
      <!-- 搜索框 -->
      <div class="search">
        <el-input placeholder="请输入内容" v-model="input">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  data(){
    return{

    }
  }
}
</script>
<style lang="less" scoped>
.drop-container {
  width: 80%;
  margin: 0 auto;
  
  .row {
    &>div{
      margin-right:30px;
      .row-header {
      border-bottom: 2px solid #e6e6e6;
      line-height: 1.25em;
      margin-top: 32px;
      padding-bottom: 14px;
    }
    }
    
  }
}

.search {
  line-height: 78px;
}

.product {
  .left {
    // background-color: pink;
    width: 50%;

    li {
      width: 20%;
      text-align: center;
      display: inline-block;
      span {
        font-size: 70px;
      }

      p {
        padding-top: 10px;
        padding-bottom: 30px;
      }
    }
  }

  .right {
    width: 50%;
    height: 400px;
  }
}

.item-border {
  border-bottom: 2px solid red;
  padding: 24px 0;
}

.header {
  height: 73px;
  border-bottom: 1px solid #e6e6e6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999; /* 确保导航栏在顶部 */
  // margin-top: 24px;
  .container {
    width: 90%;
    margin: 0 auto;
    height: inherit;
    justify-content: space-between;

    .logo {

      .img-box {
        width: 40px;
      }
    }
  }
}



.nav {
  display: flex;

  .menu-item {
    cursor: pointer;
    user-select: none;
  }
}

.item::after {
  visibility: hidden;
}

.item:hover::after {
  visibility: visible;
}

.item.up::after {
  content: '\e6e1';
}

.item.down::after {
  content: '\e6df';
}

.item {
  // background-color: pink;
  padding: 26px 20px;

  &::after {
    font-family: "element-icons";
    margin-left: 6px;
    font-size: 14px;
  }
}

.item-content {
  position: fixed;
  // background-color: salmon;
  left: 0;
  right: 0;
  height: 0;
  // top:0;
  overflow: hidden;
  z-index: 9999;
}

.toggle-content {
  padding: 30px;
  background-color: #fff;
}

.item-content-height {
  height: 88%;
  transition: height 1s ease-in-out;
}
</style>

<script>
export default {
  data() {
    return {
      isToggle: false,
      currentIndex: null,
      input: '',
      menus: [
        '智能产品',
        '系统集成',
        '服务支持',
        '合作伙伴',
        '关于绿源天成'
      ],
      contents: [
        [
          '智能产品内容1',
          '智能产品内容2',
          '智能产品内容3'
        ],
        [
          '系统集成内容1',
          '系统集成内容2',
          '系统集成内容3'
        ],
        [
          '服务支持内容1',
          '服务支持内容2',
          '服务支持内容3'
        ],
        [
          '合作伙伴内容1',
          '合作伙伴内容2',
          '合作伙伴内容3'
        ],
        [
          '关于绿源天成内容1',
          '关于绿源天成内容2',
          '关于绿源天成内容3'
        ]
      ]
    };
  },
  methods: {
    toggle(index) {
      this.currentIndex = index;

      this.isToggle = !this.isToggle;



    },
  }
};
</script>
