<template>
    <div class="nav-bar">
        <nav>
            <ul class="nav-list">
                <li :class="{ 'active': showDropdown }" @click="toggleDropdown">
                    <i class="el-icon-menu"></i>
                    集团网站
                    <i :class="showDropdown?'el-icon-arrow-down':'el-icon-arrow-up'"></i>
                </li>
                <li :class="{ 'active': showLanguage }" @click="toggleLanguage">
                    <i class="el-icon-s-promotion"></i>
                    选择区域/语言
                    <i :class="showLanguage?'el-icon-arrow-down':'el-icon-arrow-up'"></i>
                </li>
                <li class="dropdown">
                    <i class="el-icon-s-custom"></i>
                    登录
                </li>
            </ul>
            <transition name="fade">
                <div v-show="showDropdown" class="dropdown-content">
                    <div class="group-website">
                        <dl>
                            <dt>
                                <span class="active">
                                    <span class="iconfont icon-arrow-right"></span>
                                    <span class="title">
                                        集团网站
                                    </span>

                                </span>
                            </dt>
                            <dd>
                                公司介绍、新闻动态、展会活动等信息
                            </dd>
                        </dl>

                        <dl>
                            <dt>
                                <a href="http://consumer.huawei.com/cn" target="_blank"
                                    onclick="ga('send', 'event', 'header', 'switchwebsite', 'consumer'); utag.link({'tealium_event':'header_click', 'nav_name':'switchwebsite_consumer'});">


                                    <span>
                                        消费者业务网站
                                    </span>
                                    <span class="iconfont icon-arrow-right"></span>
                                </a>
                            </dt>
                            <dd>
                                手机，PC和平板等智慧生活产品
                            </dd>
                        </dl>

                        <dl>
                            <dt>
                                <a href="#" target="_blank">
                                    <span>
                                        企业业务网站
                                    </span>
                                    <span class="iconfont icon-arrow-right"></span>
                                </a>
                            </dt>
                            <dd>
                                企业商用产品、解决方案和服务
                            </dd>
                        </dl>

                        <dl>
                            <dt>
                                <a href="#" target="_blank">
                                    <span>
                                        运营商业务网站
                                    </span>
                                    <span class="iconfont icon-arrow-right"></span>
                                </a>
                            </dt>
                            <dd>
                                运营商网络解决方案、产品及服务
                            </dd>
                        </dl>

                        <dl>
                            <dt>
                                <a href="#" target="_blank">
                                    <span>
                                        华为云网站
                                    </span>
                                    <span class="iconfont icon-arrow-right"></span>
                                </a>
                            </dt>
                            <dd>
                                华为云服务及解决方案
                            </dd>
                        </dl>
                    </div>

                </div>
            </transition>

            <transition name="language">
                <div v-show="showLanguage" class="dropdown-content">
                    <div class="worldwide">

                        <div class="worldwide-language" style="">
                            <h3>选择区域/语言</h3>
                        </div>
                        <ul>
                            <li>Australia - English</li>
                            <li>China - 简体中文</li>
                        </ul>
                    </div>
                </div>
            </transition>
        </nav>
    </div>
</template>
  
<script>

export default {
    name: 'NavBar',
    components: {

    },
    data() {
        return {
            showDropdown: false,
            showLanguage: false,
        }
    },
    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
            this.showLanguage = false
        },
        toggleLanguage() {
            this.showLanguage = !this.showLanguage;
            this.showDropdown = false
        }
    }
}
</script>
<style lang="less" scoped>

nav {
    .nav-list {
        background-color: #111;
        color: #ccc;

        text-align: right;
        user-select: none;

        li {
            padding: 4px 15px;
            cursor: pointer;
            font-size: 12px;
            margin-right: 10px;
            display: inline-block;
        }
    }
}

.dropdown-content {
    background-color: #f7f7f7;
    overflow: hidden;
}

.active {
    background-color: #fff;
    color: #818181;
    transition: all;
}


.fade-enter,
.fade-leave-to {
    height: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: height .6s ease-in-out;
}

.fade-enter-to,
.fade-leave {
    height: 177px;
}



.language-enter,
.language-leave-to {
    height: 0;
}

.language-enter-active,
.language-leave-active {
    transition: height .6s ease-in-out;
}

.language-enter-to,
.language-leave {
    height: 125px;
}

.worldwide {
    padding: 20px 0;
    width: 90%;
    margin: 0 auto;

    .worldwide-language {
        padding: 20px 0;
    }

    li {
        padding-right: 30px;
    }
}

.group-website {
    display: flex;
    width: 90%;
    margin: 0 auto;

    .title {
        padding-bottom: 10px;
        font-weight: bold;
    }

    dl {
        width: 20%;
        border: none;
        padding: 40px 0;
        padding-right: 60px;
        margin-bottom: 0;

        dt {
            margin-bottom: 5px;
            font-size: 0.95em;

            &>span {
                display: inline-block;
                margin-bottom: 10px;
                position: relative;
                color: #4d4d4d;
            }

            a {
                font-weight: normal;
                min-height: 36px;
                display: block;
                color: #4d4d4d;
            }
        }

        dd {
            padding: 10px 0;
            font-size: 0.875em;
            color: #808080
        }
    }
}


</style>

